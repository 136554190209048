export const maintainAspectRatio = (el, aspectRatio) => {
  const width = el.parentNode.offsetWidth
  const height = el.parentNode.offsetHeight

  if (width > height * aspectRatio) {
    el.style.height = width / aspectRatio + "px"
    el.style.width = width + "px"
    el.style.marginLeft = "0"
    el.style.marginTop = (height - width / aspectRatio) / 2 + "px"
  } else {
    el.style.height = height + "px"
    el.style.width = height * aspectRatio + "px"
    el.style.marginLeft = (width - height * aspectRatio) / 2 + "px"
    el.style.marginTop = "0"
  }
}
