// Core
import React, { useState, useRef, useEffect } from "react"
import debounce from "lodash.debounce"
import Player from "@vimeo/player"

// Style
import styles from "./video.module.scss"
import { maintainAspectRatio } from "../../utils/video"

const Video = ({
  vidID,
  playState = false,
  autoplay = false,
  muted = false,
  loop = false,
  fill = false,
  stretch = false,
  loadCallback = null,
  playCallback = null,
}) => {
  const videoPlayer = useRef(null)
  const video = useRef(null)
  const videoEl = useRef(null)
  const videoInnerEl = useRef(null)
  const resizeVideo = debounce(maintainAspectRatio, 500)
  const [playing, setPlaying] = useState(false)
  let aspectRatio = 1.78

  const play = () => {
    videoPlayer.current
      .play()
      .then(() => {
        setPlaying(true)
        if (playCallback) playCallback()
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    addVimeoVideo()

    if (fill && stretch) {
      resizeVideo(videoInnerEl.current, aspectRatio)

      window.addEventListener(
        "resize",
        resizeVideo.bind(null, videoInnerEl.current, aspectRatio),
        false
      )
    }

    return () => {
      if (fill && stretch) {
        window.removeEventListener("resize", resizeVideo, false)
      }
    }
  }, [])

  useEffect(() => {
    if (!videoPlayer.current) return

    if (playState) {
      play()
    } else {
      videoPlayer.current.pause().then(() => {
        setPlaying(false)
      })
    }
  }, [playState])

  const addVimeoVideo = () => {
    videoPlayer.current = new Player(video.current, {
      id: vidID,
      width: "1600",
      autoplay: autoplay,
      autopause: false,
      muted: muted,
      loop: loop,
      background: stretch,
    })

    videoPlayer.current.pause()
    videoPlayer.current.on("loaded", videoLoaded)
  }

  const videoLoaded = () => {
    if (loadCallback) loadCallback()
    if (playState) {
      play()
    }
  }

  return (
    <div
      className={`${styles.videoWrap} ${fill ? styles.fill : ""} ${
        stretch ? styles.stretch : ""
      }`}
      ref={videoEl}
    >
      <div
        className={`${fill ? styles.fillInner : styles.videoResponsive}`}
        ref={videoInnerEl}
        data-video
      >
        <div id="player" className={styles.videoEmbed} ref={video} />
      </div>
    </div>
  )
}

export default Video
