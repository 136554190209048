import React from "react"
import PropTypes from "prop-types"

const ScrollSection = ({ events, children, zindex }) => {

  const baseStyles = {
    zIndex: zindex ? zindex : null,
    position: zindex ? "relative" : null
  }

  return (
    <div className="scroll-section" data-scroll-section style={baseStyles}>
      {children}
    </div>
  )
}

ScrollSection.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ScrollSection
